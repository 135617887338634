.load-more-button {
    display: block;
    margin: 20px auto;
    max-width: 100%;



    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    color: #333333;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.2s ease, color 0.2s ease;


  }
  .load-more-button:hover {
    background-color: #333333;
    color: #ffffff;
  }
  
  @media screen and (min-width: 768px) {
    .load-more-button {
      max-width: 200px;
    }
  }
  