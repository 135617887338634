#footer-container {
  background-color: #30377e;
  --bs-gutter-x: 0rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0rem;
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  justify-content: center;
}
#footerbtn {
  color: white;
  font-size: 1.5rem;
  border-radius: 10px;
  font-family: "Times New Roman", Times, serif;
  border: none;
  padding: 12px;
  width: 12rem;
  background-color: #d70101;
  font-weight: 500;
  letter-spacing: 0.8px;
  text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
}
#footerbtn:hover {
  text-decoration: underline;
}
.linebeat {
  width: 80%;
  height: auto;
}
.footer-title {
  margin: 1rem 0rem;
  color: #ffffff;
  font-family: "Roboto", Sans-serif;
  font-size: 43px;
  font-weight: 500;
}
@media screen and (max-width: 990px) {
  .linebeat {
    width: 100%;
  }
  .footer-title {
    font-size: 21px;
    margin-top: 1rem;
  }
}
