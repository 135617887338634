#blog-container {
  margin: auto;
  align-items: center;
  height: 6rem;
  width: 50%;
}
#blog-container h1 {
  position: relative;
  font-family: Time new;
}
#blog-p {
  text-align: center;
  margin-left: 12.5rem;
  color: red;
  font-size: 1rem;
  font-family: "Roboto", Sans-serif;
}
.card-container1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  margin: 25px auto;
}
@media (max-width: 998px) and (min-width: 768px) {
  .card-container1 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 765px) and (min-width: 316px) {
  .card-container1 {
    grid-template-columns: repeat(1, 2fr);
  }
}
.card-container1 .card {
  width: 100%;
  height: 30rem;
  background-color: #f7f7f7;
  position: relative;
}
.card-container1 .card img {
  width: 100%;
  height: auto;
}
.card-container1 .card .article {
  padding: 15px 20px;
}
.card-container1 .card .article a {
  font-size: 20px;
  text-decoration: none;
  color: #666;
}
.card-container1 .card .article a:hover {
  color: #63b03e;
}
.card-container1 .card .article p {
  font-size: 16px;
  margin: 10px 0px;
  color: grey;
}
.card-container1 .card .blog-view {
  padding: 0px 20px;
}
.card-container1 .card .blog-view a {
  color: #666;
  text-decoration: none;
  font-size: 20px;
}
.card-container1 .card .blog-view a:hover {
  color: #63b03e;
}
#wave {
  background-image: url("../assets/Newslatter-Bg-8.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 35rem;
  display: flex;
  flex-wrap: wrap;
}
.wave-title {
  padding: 6rem;
  color: #ffffff;
  font-family: "Roboto", Sans-serif;
  font-size: 36px;
  font-weight: 600;
  padding-bottom: 1rem;
}
#wave-title2 {
  color: #ffffff;
  font-family: "Roboto", Sans-serif;
  font-size: 36px;
  font-weight: 600;
}
#wave-img {
  margin: 13rem 6rem 4rem 6rem;
}
@media screen and (max-width: 998px) {
  .wave-title {
    padding: 6rem 0rem 0rem;
  }
  #wave-img {
    margin: 0rem;
    max-width: 100%;
    height: auto;
  }
  #blog-container {
    width: 100%;
  }
  #blog-p {
    text-align: center;
    margin-left: 0rem;
  }
}
/*
 background-image: url("	https://www.texasinet.com/wp-content/uploads/2021/09/Newslatter-Bg-8.png");
  background-repeat: no-repeat;
  background-size: cover;
*/
.category_navbar {
  padding-top: 50px;
  text-align: center;
}

.category_navbar h1 {
  padding-bottom: 20px;
  text-transform: capitalize;
}

/** button group styles **/
.Category_btn_group {
  border-radius: 1rem;
  text-transform: capitalize;
}
.Category_btn_group_item {
  border: none;
  /* min-width: 6rem; */
  padding: 1.5rem 3rem;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  margin: 0;
  font-size: 17px;
  color: rgb(28, 31, 6);
  box-shadow: inset 0px 0px 0px -15px rgb(138, 153, 7);
  transition: all 300ms ease-out;
  text-transform: capitalize;
}
.Category_btn_group_item:last-child {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.Category_btn_group_item:first-child {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.Category_btn_group_item:hover,
.Category_btn_group_item:focus {
  color: #fff;
  background-color: #002c6f;
  box-shadow: inset 0px -20px 0px -15px #002c6f;
  font-size: 12px;
}
.Category_btn_group_item:focus {
  outline: none;
}
.Category_btn_group_item:after {
  content: "✔️";
  margin-left: 0.5rem;
  display: inline-block;
  color: rebeccapurple;
  position: absolute;
  transform: translatey(10px);
  opacity: 0;
  transition: all 200ms ease-out;
}
.Category_btn_group_item--active:after {
  opacity: 1;
  transform: translatey(-2px);
}
.CategoryNavbar_iconbtn {
  width: 4rem;
  height: 4rem;
  padding: 1px;
  margin-left: 5px;
}
@media (max-width: 487px) and (min-width: 300px) {
  .wave-title {
    padding: 4rem 1rem;
    color: #ffffff;
    font-family: "Roboto", Sans-serif;
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 600;
  }
  #wave-title2 {
    font-size: calc(1.375rem + 1.5vw);
  }
  #wave {
    height: auto;
  }
}
