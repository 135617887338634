.hoverbtn {
  width: 240px;
  height: 70px;
  font-size: 25px;
  font-family: Poppins;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  overflow: hidden;
  background-color: #fff;
  border-color: #ffb9b9;
  outline: none;
  background-size: 50px 50px;
  transition: 0.8s;
}
#mobile-show {
  display: none;
}
.two {
  background: #e30303;
  color: #fff;
}
.hoverbtn:hover {
  text-shadow: 3px 1px 5px #333;
  color: rgb(2, 2, 2);
  transition: 0.8s;
  animation: strip 6s linear infinite;
}
.one:hover {
  background-image: linear-gradient(
    45deg,
    #df7791 16.67%,
    #fff 16.67%,
    #fff 33.33%,
    #df7791 33.33%,
    #df7791 50%,
    #df7791 50%,
    #df7791 66.67%,
    #fff 66.67%,
    #fff 83.33%,
    #df7791 83.33%,
    #df7791 100%
  );
}
@keyframes strip {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 50% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}
.tech-title {
  color: var(--bs-orange);
  /* font-style: italic; */
  font-size: 2rem;
  font-weight: 600; 
}
.tect-p {
  color: #000000;
  font-family: "Antonio", Sans-serif;
  font-size: 2rem;
  font-weight: 600;  
}.marg{
  margin-top: 11rem;
}
.card-container {
  justify-content: center;
  align-items: center;
}
.flipbox.flipBottom {
  margin-top: 10px;
}
.flipbox {
  width: 281px;
  height: 304px;
  text-align: center;
  margin: 0 auto;
  -webkit-transition: all 350ms ease;
  -moz-transition: all 350ms ease;
  -o-transition: all 350ms ease;
  transition: all 350ms ease;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.card {
  width: 281px;
  height: 304px;
  margin: auto;
  cursor: pointer;
  perspective: 1000px;
  border: none;
  border-radius: 30px;
}
.inner-box {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius: 30px;
  transition: transform 1s;
  transform-style: preserve-3d;
}
.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 30px;
}
.card-front {
  background: #ee5e46;
}
.card-front.n {
  background: #405463;
}
.s {
  background: #0095a6;
}
.d {
  background: #851f55;
}
.plusicon {
  margin-top: 70px;
  font-size: 4rem;
  color: #fff;
}
.card-title {
  color: #fff;
  font-weight: 600;
  margin-top: 0px;
  font-family: Times New Roman, Sans-serif;
  text-transform: capitalize;
  font-size: 1.75rem;
}
.card-back {
  background-color: #002c6f;
  transform: rotateX(180deg);
}
.card:hover .inner-box {
  transform: rotateX(-180deg);
}
.ul {
  line-height: 20px;
  margin-top: 60px;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 400;
  list-style: none;
  padding: 0rem;
}
.card-sub {
  color: #e30303;
  font-family: "Roboto", Sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
}
.card-sub1 {
  color: #e30303;
  font-family: "Roboto", Sans-serif;
  font-weight: 600;
  font-size: 2rem;
}
.elementor-element-087ad86 {
  text-align: center;
  color: #7a7a7a;
  font-family: "Poppins", Sans-serif;
  font-size: 21px;
  font-weight: 300;
  line-height: 1.7em;
}
.cart-me {
  color: #000000;
  font-family: "Times New Roman", Sans-serif;
  font-size: 4rem;
  font-weight: 400;
}
/*.elementor-background-overlay {
  opacity: 0.5;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-heading-title {
  color: #FFFFFF;
  font-family: "Roboto", Sans-serif;
  font-size: 53px;
  font-weight: 400;
  text-shadow: 0px 0px 5px rgb(0 0 0 / 92%);
} */
.background-overlay {
  background-image: url("../assets//Asset-10-8-1.png");
  background-position: 1px -239px;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.9;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-background-overlay,
.elementor .elementor-background-slideshow {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.heading-title {
  color: #ffffff;
  font-family: "Roboto", Sans-serif;
  font-size: 53px;
  font-weight: 400;
  text-shadow: 0px 0px 5px rgb(0 0 0 / 92%);
  text-align: center;
}
#heading {
  color: #ffffff;
  font-size: 2rem;
  font-family: "Roboto", Sans-serif;
  font-weight: 300;
  text-shadow: 0px 0px 5px rgb(0 0 0 / 30%);
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 850px) {
  .background-overlay {
    background-position: 100% 50%;
  }
  .heading-title {
    font-size: 33px;
  }
  #heading {
    font-size: 1.2rem;
  }
  #doingimg {
    width: 100%;
    height: auto;
  }
}
#bav-title {
  color: #000000;
  font-family: "Times New Roman", Sans-serif;
  font-size: 3rem;
  font-weight: 800;
  line-height: 1;
}
.bav-sub-title {
  font-size: 2rem;
  font-family: "Times New Roman", Sans-serif;
  font-weight: 800;
}
.elementor-element-70a0dd5 {
  text-align: left;
  color: #7a7a7a;
  font-family: "Poppins", Sans-serif;
  font-size: 21px;
  font-weight: 300;
  line-height: 1.9em;
}
#doingtitle {
  color: #785e52;
  font-family: "Roboto", Sans-serif;
  font-size: 4rem;
  font-weight: 600;
}
#lastlytitle {
  color: #9b211e;
  font-family: "Roboto", Sans-serif;
  font-size: 4rem;
  font-weight: 600;
  margin-left: 8rem;
}
@media only screen and (max-width: 998px) {
  #lastlytitle {
    margin-left: 0rem;
  }
  #doingtitle {
    font-size: 2rem;
  }
  .card-sub {
    font-size: 1rem;
  }
  .card-sub1 {
    font-size: 0.9rem;
  }
  .cart-me {
    font-size: 2rem;
  }
  #bav-title {
    font-size: 2rem;
    font-weight: 700;
  }
  .tect-p {
    text-align: center;
    color: #000000;
    font-family: "Antonio", Sans-serif;
    font-size: 2rem;
    font-weight: 600;
     
  }.bod{
    border:10px solid blue !important;
  }
  .tech-title {
    font-size: 1rem;
    text-align: center;
  }
  .elementor-heading-title {
    font-size: 1rem;
    text-align: center;
  }
  .hoverbtn {
    margin: auto;
  }
  #cyber {
    font-size: 0.7rem;
    text-align: center;
  }
}
#cyber {
  margin-left: 12px;
  color: #0a93a5;
  font-family: "Antonio", Sans-serif;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 600; 
}
.mar {
  margin-top: 3rem;
  color: #ec0000;
  font-family: "Roboto", Sans-serif;
  font-weight: 600;
}
@media (max-width: 486px) and (min-width: 300px) {
  .tect-p {
    font-size: 25px;
  }
  #cyber {
    font-size: 26px;
  }
  .tech-title {
    font-size: 26px;
  }
  .cart-me {
    font-size: 42px;
  }
  .card-sub1 {
    font-size: 19px;
    margin: 1rem 0rem;
  }
  .elementor-element-087ad86 {
    font-size: 19px;
    color: #7a7a7a;
  }
  .card {
    margin: 1rem auto;
  }
  .ul {
    text-align: center;
    font-size: 1rem;
    padding: 0rem;
  }
  .dot-p {
    font-size: 1rem;
    margin-right: 5px;
    font-weight: 500;
  }
  #bav-title {
    font-size: 30px;
    text-align: center;
    margin-top: 16px;
  }
  .elementor-element-70a0dd5 {
    font-size: 19px;
    text-align: center;
    margin-top: 12px;
  }
  .one {
    margin-top: 4rem;
  }
  .bav-sub-title {
    font-size: 33px;
  }
  .loading1 {
    max-width: 39%;
  }
  .mar {
    font-size: 20px;
  }
  .scale {
    max-width: 29%;
  }
  .watch {
    max-width: 24%;
  }
  #doingtitle {
    font-size: 32px;
    margin-top: 15px;
  }
  #doingimg {
    max-width: 50%;
  }
  #lastlytitle {
    font-size: 57px;
    text-align: center;
    margin-top: 12px;
  }
  #mobile-show {
    display: block;
  }
  #small-mobile-none {
    display: none;
  }
}
